import React from 'react'
import companyInfo from '../company.json'

const ContactItem = ({ title, address, telephone, email }) => {
    return (
        <div className="teaser-item">
            <div className="teaser-item-bg">
                <h1 className="pos-title">{title}</h1>
                <h2 className="pos-subtitle">{address}</h2>
                <div className="floatbox">
                    <div className="pos-content">
                        <div className="element element-textarea first last">
                            <div>
                                <table className="zebra">
                                    <tbody>
                                    <tr className="odd">
                                        <td className="bold">Telephone</td>
                                        <td>{telephone}</td>
                                    </tr>
                                    <tr>
                                        <td className="bold">Email</td>
                                        <td><span id="cloak96596"><a
                                            href={`mailto:${email}`}>{email}</a></span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Contacts = () => {
    const { email, phone } = companyInfo
    return (
        <div id="main" className="grid-block">
            <div id="maininner" className="grid-box">
                <section id="content" className="grid-block">
                    <div id="yoo-zoo" className="yoo-zoo blog-default blog-default-frontpage">
                        <div className="items items-col-2">
                            <div className="width100 first">
                                <ContactItem
                                    title="Panama Office"
                                    address="Nuevo Reparto, Parque Lefevre, Panama City"
                                    telephone={phone}
                                    email={email}
                                />
                            </div>
                            <div className="width100 last">
                                <ContactItem
                                    title="Costa Rica Office"
                                    address="Guachipelin, Escazu, San José"
                                    telephone="+506 6109-2749"
                                    email={email}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Contacts