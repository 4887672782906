import React, { Component } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import companyInfo from './company.json'
import { Home, Services, AboutUs, Contacts} from './pages'
import Menu from './components/Menu'
import Footer from './components/Footer'
import TopBar from './components/TopBar'
import "./assets/css/styles.css"
import "./assets/css/override.css"
import logo from './assets/images/logo.png'

const Main = () => (
    <main>
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/services' component={Services}/>
            <Route path='/about-us' component={AboutUs}/>
            <Route path='/contacts' component={Contacts}/>
        </Switch>
    </main>
)

class App extends Component {
    render() {
        const { name } = companyInfo
        return (
            <div>
                <div id="block-page">
                    <div id="page-bg">
                        <TopBar/>
                        <div id="block-main">
                            <div className="wrapper clearfix">
                                <header id="header" className="grid-block">
                                    <Link to="/" id="logo">
                                        <img src={logo} alt={name} width={375} height={60}/>
                                    </Link>
                                    <Menu/>
                                </header>
                                <Main/>
                            </div>
                        </div>
                    </div>
                </div>

               <Footer/>
            </div>
        )
    }
}

export default App
